import React from "react"
import styled from "styled-components"
import MVModal from "../MVModal"
import CloseIcon from "../Icons/CloseIcon"
import YouTube from "react-youtube"

const ModalVideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  * {
    height: 100%;
  }
`

const CloseModal = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 102;

  & svg {
    width: 20px;
    fill: #ffffff;

    &:hover {
      cursor: pointer;
    }
  }
`

export default class MVVideoModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showCloseIcon: true,
    }
  }

  render() {
    const { onClose, isOpen } = this.props

    const opts = {
      width: "100%",
      height: "100%",
      playerVars: {
        autoplay: 1,
      },
    }

    return (
      <MVModal isOpen={isOpen} onCloseCallback={onClose}>
        <CloseModal onClick={onClose}>
          <CloseIcon />
        </CloseModal>
        <ModalVideoContainer>
          <YouTube videoId="n0yrCDwJbho" opts={opts} onReady={this._onReady} />
        </ModalVideoContainer>
      </MVModal>
    )
  }
}
